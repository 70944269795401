/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text-underline': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.833 8V1.167h1.334v-1H1.5v1h1.333V8a5.167 5.167 0 0010.334 0V1.167H14.5v-1h-3.667v1h1.334V8a4.167 4.167 0 01-8.334 0zM15.833 15.833v-1H.167v1h15.666z" _fill="#212121"/>'
  }
})
