/* eslint-disable */
require('./arrow-fill-negative')
require('./arrow')
require('./download')
require('./eraser-fill-negative')
require('./eraser')
require('./hand-fill-negative')
require('./hand')
require('./line-fill-negative')
require('./line')
require('./lock')
require('./magnifying-glass')
require('./pencil-fill-negative')
require('./pencil')
require('./redo')
require('./select-fill-negative-2')
require('./select-fill-negative')
require('./select')
require('./shape-fill-negative')
require('./shape')
require('./text-bold')
require('./text-fill-negative')
require('./text-italic')
require('./text-line-through')
require('./text-underline')
require('./text')
require('./trash')
require('./undo')
require('./Vlt-icon-check-bold')
require('./Vlt-icon-down-full')
require('./Vlt-icon-more-v-negative')
